@import "./assets/style/custom-material.scss";

@mixin noSpace() {
  padding: 0;
  margin: 0;
}

html,
body {
  @include noSpace();
  width: 100%;
}

body {
  background-color: #cccccc20;
}

.mat-form-field {
  width: 100%;
}

.administration-title {
  padding: 1rem 1rem 1rem 0.25rem;
  display: flex !important;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .mat-icon {
    margin-right: 0.5rem;
  }
}

table {
  width: 100%;
}

.search-form-field {
  width: 200px;
}

.modal-desktop {
  height: 80%;
  width: 80%;
}

.mat-card {
  margin-bottom: 0.5rem;
}

div.horizontal-delimiter {
  padding: 0.5rem;
  border-top: rgba(0, 0, 0, 0.12) solid 1px;
}

div.vertical-delimiter {
  padding: 0.5rem;
  border-right: rgba(0, 0, 0, 0.12) solid 1px;
  height: 100%;
}

div.form-wrapper {
  padding: 0.25rem;
}

.mat-divider-horizontal {
  width: 100%;
  padding-bottom: 1rem;
}

.line-broken-tooltip {
  white-space: pre-line;
}

.mat-menu-content:not(:empty) {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.is-selected {
  background-color: mat-color($my-custom-accent);
}

.is-site-status-error {
  color: mat-color($my-custom-warn);
  font-weight: bolder;
}

.snackbar-white-space {
  white-space: pre-wrap;
}

.cell-unavailable {
  background-color: #8b8b8b40;
}

mat-table {
  display: block;
}

mat-row,
mat-header-row,
mat-footer-row {
  display: flex;
  border-width: 0;
  border-bottom-width: 1px;
  border-style: solid;
  align-items: center;
  box-sizing: border-box;
}
mat-row::after,
mat-header-row::after,
mat-footer-row::after {
  display: inline-block;
  min-height: inherit;
  content: "";
}
mat-cell:first-of-type,
mat-header-cell:first-of-type,
mat-footer-cell:first-of-type {
  padding-left: 24px;
}
[dir="rtl"] mat-cell:first-of-type:not(:only-of-type),
[dir="rtl"] mat-header-cell:first-of-type:not(:only-of-type),
[dir="rtl"] mat-footer-cell:first-of-type:not(:only-of-type) {
  padding-left: 0;
  padding-right: 24px;
}
mat-cell:last-of-type,
mat-header-cell:last-of-type,
mat-footer-cell:last-of-type {
  padding-right: 24px;
}
[dir="rtl"] mat-cell:last-of-type:not(:only-of-type),
[dir="rtl"] mat-header-cell:last-of-type:not(:only-of-type),
[dir="rtl"] mat-footer-cell:last-of-type:not(:only-of-type) {
  padding-right: 0;
  padding-left: 24px;
}
mat-cell,
mat-header-cell,
mat-footer-cell {
  flex: 1;
  display: flex;
  align-items: center;
  overflow: hidden;
  word-wrap: break-word;
  min-height: inherit;
}
table.mat-table {
  border-spacing: 0;
}
tr.mat-header-row {
  height: 30px;
}
tr.mat-row,
tr.mat-footer-row {
  height: 30px;
}
th.mat-header-cell {
  text-align: left;
}
[dir="rtl"] th.mat-header-cell {
  text-align: right;
}
th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
  padding: 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
th.mat-header-cell:first-of-type,
td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type {
  padding-left: 24px;
}
[dir="rtl"] th.mat-header-cell:first-of-type:not(:only-of-type),
[dir="rtl"] td.mat-cell:first-of-type:not(:only-of-type),
[dir="rtl"] td.mat-footer-cell:first-of-type:not(:only-of-type) {
  padding-left: 0;
  padding-right: 24px;
}
th.mat-header-cell:last-of-type,
td.mat-cell:last-of-type,
td.mat-footer-cell:last-of-type {
  padding-right: 24px;
}
[dir="rtl"] th.mat-header-cell:last-of-type:not(:only-of-type),
[dir="rtl"] td.mat-cell:last-of-type:not(:only-of-type),
[dir="rtl"] td.mat-footer-cell:last-of-type:not(:only-of-type) {
  padding-right: 0;
  padding-left: 24px;
}
.mat-table-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}
.mat-table-fixed-layout {
  table-layout: fixed;
}

.mat-menu-panel {
  max-width: unset !important;
  min-height: unset !important;
}

.hours-td {
  font-size: 10px !important;
  padding: 0 !important;
}
