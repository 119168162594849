@import "~@angular/material/theming";
@include mat-core();

$green-palette: (
  50: #e8f3e6,
  100: #c5e2c1,
  200: #9fcf97,
  300: #78bb6d,
  400: #5bad4e,
  500: #3e9e2f,
  600: #38962a,
  700: #308c23,
  800: #28821d,
  900: #1b7012,
  A100: #adffa6,
  A200: #7eff73,
  A400: #4fff40,
  A700: #37ff26,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
  ),
);

$blue-palette: (
  50: #e6e8f3,
  100: #c1c5e2,
  200: #979fcf,
  300: #6d78bb,
  400: #4e5bad,
  500: #2f3e9e,
  600: #2a3896,
  700: #23308c,
  800: #1d2882,
  900: #121b70,
  A100: #a6adff,
  A200: #737eff,
  A400: #404fff,
  A700: #2637ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$red-palette: (
  50: #f3e6e8,
  100: #e2c1c5,
  200: #cf979f,
  300: #bb6d78,
  400: #ad4e5b,
  500: #9e2f3e,
  600: #962a38,
  700: #8c2330,
  800: #821d28,
  900: #70121b,
  A100: #ffa6ad,
  A200: #ff737e,
  A400: #ff404f,
  A700: #ff2637,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

/* ======== Angular material custom themes ======== */
$my-custom-primary: mat-palette($blue-palette);
$my-custom-accent: mat-palette($green-palette, 700, A700);
$my-custom-warn: mat-palette($red-palette, A700);

$my-custom-theme: mat-light-theme(
  $my-custom-primary,
  $my-custom-accent,
  $my-custom-warn
);

@include angular-material-theme($my-custom-theme);
